import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Button from "../components/Button"
import Heading from "../components/Layout/heading"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <Heading title="Not Found" />
    <p>Sorry, the requested URL can't be found</p>
    <Link to="/">
      <Button
        btnStyle="sca-primary"
        customClass="btn-icon-right"
        icon="home"
        iconPos="left"
      >
        Homepage
      </Button>
    </Link>
    <p></p>
  </Layout>
)

export default NotFoundPage
